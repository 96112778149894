

header .header_img {
    background-image: url(../utils/img/header-img.jpg) !important;
}
header .HeaderCol1
{
    background-color: #C9E5EF;
}

.HeaderStackStyle {
    width: 50%;
    margin-top: 10%;
    margin-left: 15%;
}

.cardImage1{
    width: 20% !important;
}



@media only screen and (max-width: 768px) {
    .HeaderStackStyle {
        width: 50%;
        margin-top: 20%;
        margin-left: 15%;
    }
}

@media only screen and (max-width: 576px) {
    .HeaderStackStyle {
        width: 70%;
        margin-top: 30%;
        text-align: center !important;
        margin-left: 15%;
        align-items: center;
    }
}

/* ************************ANIMATIONS******************************* */
.container-logo {
    overflow: hidden;
   
  }

  .container-logo  .slider {
    animation: slidein 30s linear infinite;
    white-space: nowrap;
   
  }
.container-logo .logos {
    width: 100%;
    display: inline-block;
    margin: 0px 0;
    
  }
  .container-logo .fab {
    width: calc(100% / 7);
    margin-right: 2%;
    animation: fade-in 0.5s 
      cubic-bezier(0.455, 0.03, 0.515, 0.955) forwards;
  }
  
  @keyframes slidein {
    from {
      transform: translate3d(0, 0, 0);
    }
    to {
      transform: translate3d(-100%, 0, 0);
    }
  }
  
  @keyframes fade-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  