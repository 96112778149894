.ContactInfo p 
{
    font-size: 1.2vw !important;
}

.contact-margin-start
{
    margin-left : 3rem !important;
}

@media only screen and (max-width: 768px) {
    .ContactInfo p  {
        font-size: 2vw !important;
        /* background-color: #C9E5EF !important; */
    }
    .contact-margin-start
    {
        margin-left : 2rem !important;
    }
}

@media only screen and (max-width: 576px) {
    .ContactInfo p  {
        font-size: 3.5vw !important;
        /* background-color: #C9E5EF !important; */
    }
    .contact-margin-start
    {
        margin-left : 2rem !important;
    }
}
 