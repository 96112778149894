@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif;
}


.SecManufactureCard {

  background-color: #C9E5EF !important;

}

.bg-Worth-color-1 {
  background-color: #00356a !important;
}


.SecManufactureCardImage {
  width: 40% !important;
  animation-name: example;
}

p {
  font-size: 1.2rem !important;
}

.home-page {
  overflow-x: hidden !important;
}

h3 {
  font-size: 1.5vw;
}

.divider-1 {
  width: 63px !important;
  height: 4px;
  position: relative;
  /* margin-bottom: 20px; */
  background: #00356a;
}


.divider-1:before,
.divider-1:after {
  width: 5px;
  height: 5px;
  content: "";
  position: absolute;
  border-radius: 50%;
  background-color: #00356a;
}


.divider-1:before {
  right: -9px;
}


.divider-1:after {
  right: -18px;
}


.list-bullets {
  list-style: none;
}

.list-bullets li {
  display: flex;
  align-items: center;
}

.list-bullets li::before {
  content: '';
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #5784d7;
  border: 2px solid #8fb3f5;
  display: block;
  margin-right: 1rem;
}

.FooterTittleColor {

  color: #011968 !important;

}

button {
  background-color: #00356a !important;
}


.navbar-toggler {
  background-color: white !important;
}


.btn-more {
  border-color: #00356a !important;
  color: #00356a !important;
  ;
  background-color: #C9E5EF !important;
}

.dropdown-item:active {
  color: #ffffff !important;
  text-decoration: none;
  background-color: #00356a !important;
}

.fs-3vw {
  font-size: 1.2vw !important;
}

.fs-4vw {
  font-size: 1.5vw !important;
}


.pl-5-resp {
  padding-left: 5rem !important;
}

.mt-6-resp {
  margin-top: -4.5px !important;
}

@media only screen and (max-width: 768px) {
  .fs-3vw {
    font-size: 3vw !important;
  }

  .fs-4vw {
    font-size: 6vw !important;
  }

  .pl-5-resp {
    padding-left: 1rem !important;
  }

  .mt-6-resp {
    margin-top: 0px !important;
  }
}

@media only screen and (max-width: 576px) {
  .fs-3vw {
    font-size: 3.5vw !important;
  }

  .fs-4vw {
    font-size: 6vw !important;
  }

  .pl-5-resp {
    padding-left: 1rem !important;
  }

  .mt-6-resp {
    margin-top: 0px !important;
  }
}