.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.feedback {
  background-color : #00356a;
  color: white;
  padding: 10px 20px;
  border-radius: 4px;
  border-color: #00356a;
  text-decoration: none;
  font-weight: 500;
}

#mybutton {
  position: fixed;
  bottom: 5px;
  right: 10px;
}
