.contact-page
{
    overflow-x: hidden !important;
}

.contact-page header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.288), rgba(0, 0, 0, 0.37)), url(../utils/img/contact-page-img.jpg);
    height: 40vh;
}

.ContactCard
{
    background-color: #C9E5EF !important;
}


@media only screen and (max-width: 768px) {
    .contact-page header {
        /* background-image: linear-gradient(rgba(0, 0, 0, 0.288), rgba(0, 0, 0, 0.37)), url(../utils/img/contact-page-img.jpg); */
        height: 25vh;
    }
 
}

@media only screen and (max-width: 576px) {
    .contact-page header {
        height: 25vh;
    }
   
}