.ContactForm
{
   width: 30rem !important;
}


@media only screen and (max-width: 768px) {
    .ContactForm {
        width: 25rem !important;
        /* background-color: #C9E5EF !important; */
    }
}

@media only screen and (max-width: 576px) {
    .ContactForm {
        width: 23rem !important;
        /* background-color: #C9E5EF !important; */
    }
}
 