.about-page header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../utils/img/about-page-img.jpg);
    height: 40vh;
}

.cardKey
{
  padding: 1rem !important;
}

/* three */
/* blockquote {padding: 15px; font-size: 16px; line-height: 18px;
    font-family: 'Times New Roman', Times, serif;}
    
    blockquote.three {background:#d8e9ff;border-left:1em solid #cad2e4;margin:1.5em 1em;padding:.5em 1em;quotes:"\201C""\201D";}
    blockquote.three:before{color:#00356a;;content:open-quote;font-size:8em;line-height:1em;margin-right:.25em;vertical-align:.1em;}
    blockquote.three:after{color:#00356a;;content:close-quote;font-size:8em;line-height:1em;margin-left:.25em;vertical-align:.1em;}
    blockquote.three:first-child{display:inline;}
    blockquote.three:last-child{margin-bottom:0;}
    .three h3{
      margin-top: -15px;
    }
    
    .three p{
      display: inline !important;
     font-size: 1.3vw !important;
     line-height: 2em;
    }
     */

    .blockquote-custom {
      position: relative;
      font-size: 1.1rem;
    }
    
    .blockquote-custom-icon {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: -25px;
      left: 50px;
    }
    
.blockquote-custom-icon-end{
  width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: -25px;
    transform: rotate(180deg);
    right: 50px;
}
    @media only screen and (max-width: 768px) {
      .about-page header {
        height : 25vh !important;
      }

   
  }
  
  @media only screen and (max-width: 576px) {
    .about-page header {
      height : 25vh !important;

    }
  }

  #clients {
    padding: 60px 0;
    
}
#clients .clients-wrap {
    border-top: 1px solid #d6eaff;
    border-left: 1px solid #d6eaff;
    margin-bottom: 30px;
}

#clients .client-logo {
    position: relative;
    /* width: 50%; */
    padding: 64px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-right: 1px solid #d6eaff;
    border-bottom: 1px solid #d6eaff;
    overflow: hidden;
    background: #fff;
    height: 160px;
   
}

.client-logo img{
    transition: all 0.4s ease-in-out;
    display: block;
    width: 100%;
    height: auto;
}

.client-logo span{
color: black;
}

  
  .overlayImg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #00356a;
  }
  
  .client-logo:hover .overlayImg {
    opacity: 1;
  }
  
  .client-logo span{
     
        color: white;
        font-size: 20px;
        font-weight: 500;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
      
}