.navCustom {
    color: #00356a !important;
    background: #fff !important;
    position: relative !important;
    border-top: 1px solid #00356a !important;
    border-radius: 10px !important;
}

.navCustom:hover {
    background-color: #e8faff !important;
    color: #00356a !important;
    cursor: pointer;
}

.navCustom.active {
    color: #e8ffe9 !important;
    background: #00356a !important;

}

.navCustom::before {
    width: 0px;
    height: 0px;
    content: '' !important;
    display: block !important;
    position: absolute !important;

}

/* Add indicator arrow for the active tab */
/* @media (min-width: 992px) {

} */



/* _------------------NavCustomTabs------- */
.navCustomTab {
    color: #00356a !important;
    background: #fff !important;
    position: relative !important;
    border-top: 1px solid #00356a !important;
    border-radius: 10px !important;
}

.navCustomTab:hover {
    background-color: #e8faff !important;
    color: #00356a !important;
    cursor: pointer;
}

.navCustomTab.active {
    color: #e8ffe9 !important;
    background: #00356a !important;

}

.navCustomTab::before {
    width: 0px;
    height: 0px;
    content: '' !important;
    display: block !important;

    /* border-right: 13px solid #00356a !important; */
    position: absolute !important;
    right: 50% !important;
    bottom: -10px !important;
    transform: translate(50%, -10%) rotate(-45deg);
    /* opacity: 0 !important; */
}

/* Add indicator arrow for the active tab */
/* @media (min-width: 992px) {

} */

.navCustomTab.active::before {
    width: 25px;
    height: 25px;
    content: '' !important;
    display: block !important;
    border-left: 14px solid #00356a !important;
    border-bottom: 14px solid #00356a !important;
    /* border-right: 13px solid #00356a !important; */
    position: absolute !important;
    right: 50% !important;
    bottom: -10px !important;
    transform: translate(50%, -10%) rotate(-45deg);

    /* opacity: 1 !important; */
}

/* ---------------------------------------- */

.product-tabs a {
    font-size: 1.2vw !important;
    background-color: #C9E5EF !important;
}


.product-page header {

    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../utils/img/CuttingTools.png);
    /* height: 50vh; */


}

.product-page1 header {
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../utils/img/about-page-img.jpg);
    /* height: 50vh; */

}
.product-header-link{
    position: absolute;
    margin-left: 70%;
    margin-top: 5%;
    margin-bottom: -8%;
}

@media only screen and (max-width: 768px) {
    .product-tabs a {
        font-size: 3vw !important;
        /* background-color: #C9E5EF !important; */
    }

    .navCustom.active::after {
        width: 0px;
        height: 0px;
        content: '' !important;
        display: block !important;

    }

    .tabContent {
        font-size: 3vw !important;
    }
    .product-header-link 
   { position: absolute;
        margin-left: 0%;
        margin-top: 0%;
        margin-bottom: -35%;
    }
}

@media only screen and (max-width: 576px) {
    .product-tabs a {
        font-size: 3vw !important;
        /* background-color: #C9E5EF !important; */
    }

    .tabContent {
        font-size: 3vw !important;
    }

    .product-page header {

        height: 25vh;


    }

    .product-page1 header {
        height: 25vh;

    }

    .product-header-link 
    {position: absolute;
        margin-left: 0%;
        margin-top: 0%;
        margin-bottom: -35%;
    }
    .navCustom.active::after {
        width: 0px;
        height: 0px;
        content: '' !important;
        display: block !important;

    }
}

@media only screen and (min-width: 992px) {
    .product-tabs a {
        font-size: 1vw !important;
    }

    .tabContent {
        font-size: 1vw !important;
    }

    .navCustom.active::after {
        width: 20px;
        height: 20px;
        content: '' !important;
        display: block !important;
        border-right: 10px solid #00356a !important;
        border-top: 10px solid #00356a !important;
        border-bottom: 10px solid #00356a !important;
        position: absolute !important;
        left: 92% !important;
        top: 30%;
        bottom: 12px !important;
        transform: translate(50%, -10%) rotate(45deg);
    }

    .product-page header {

        height: 40vh;


    }

    .product-page1 header {
        height: 40vh;

    }
    .product-header-link{
        position: absolute;
        margin-left: 70%;
        margin-top: 5%;
        margin-bottom: -8%;
    }
    
}


@media only screen and (max-width: 992px) {
    .product-tabs a {
        font-size: 3vw !important;
        /* background-color: #C9E5EF !important; */
    }
}

